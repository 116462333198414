import React from "react"

//import { Helmet } from "react-helmet"
    
import Layout from "../components/layout"
import Seo from "../components/seo"


const replaceValidation = e =>{
    let val = e.target.value
    //全角を半角に修正
    function han2Zen(str) {
        return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        });
    }
    let replaceVal = han2Zen(val);
    e.target.value = replaceVal
    //バリデーション
    Validation(e)
}

let validFlug = [0,0,0,0,0]
const Validation = e =>{
    let validationMessage = e.target.validationMessage
    let formName = e.target.name
    
    var i
    if(formName==="company"){
        i = 0
    }else if(formName==="name"){
        i = 1
    }else if(formName==="mail"){
        i = 2
    }else if(formName==="tel"){
        i = 3
    }else if(formName==="text"){
        i = 4
    }
    if(validationMessage === ""){
        e.target.style.background="whitesmoke"
        validFlug[i] = 1
    }else{
        e.target.style.background="pink"
        validFlug[i] = 0
    }
    //送信ボタン
    let submitFlug = [1,1,1,1,1]
    let submit = document.getElementById("submit")
    if(validFlug.toString() === submitFlug.toString()){
        submit.style.filter="none"
        submit.style.pointerEvents="auto"
    }else{
        submit.style.filter="grayscale(100%)"
        submit.style.pointerEvents="none"
    }
}

const contactHtml = ({data,location}) => (
	<Layout>
	  <Seo
		pagetitle="CONTACT"
		pagedesc="MAZINへのお問い合せ"
		pagepath={location.pathname}
	  />
      <div className="contact">
        <div className="row">
          {/*<div class="contents-title">CONTACT</div>*/}
          <h1>CONTACT</h1>
          <div className="contents-box">
            <h3 className="underline">お問い合せはこちら</h3><div />
            <div className="contents-comment">
              <div className="comment-br">
                この度は、MAZIN のWEBサイトをご覧いただき誠にありがとうございます。<br />
                製品の詳細についてはもちろん、現在お抱えの課題に対してMAZINならではの改善策をご提案させていただきます。
              </div>
              <div className="comment-br">
                「強い工場を造る。」<br />
                ために、皆様からのご相談、お問い合せを、MAZIN一同心よりお待ちしております。
                <div className="small">＊入力は全て必須項目となっております</div>
              </div>
            </div>
            <div className="contact-wrap">
              <form name="contact" method="POST" action="/contact-thanks/" data-netlify="true">
                <div className="contact-table">
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="th">会社名</div>
                    <div className="td">
                        <input type="text" name="company" required
                            placeholder="ZZZ株式会社"
                            onBlur={Validation}
                            onInput={Validation}
                        />
                    </div>
                    <div className="th">氏名</div>
                    <div className="td">
                        <input type="text" name="name" required
                            placeholder="ZZZ"
                            onBlur={Validation}
                            onInput={Validation}
                        />
                    </div>
                    <div className="th line-2"><div><span>勤務先</span><span>メールアドレス</span></div></div>
                    <div className="td">
                        <input type="email" name="mail" required
                            placeholder="ZZZ@mazin.tech"
                            onBlur={Validation}
                            onInput={Validation}
                        />
                    </div>
                    <div className="th">電話番号(携帯)</div>
                    <div className="td">
                        <input type="tel" name="tel" pattern="[\d\-]*" required
                            placeholder="00-0000-0000"
                            onBlur={replaceValidation}
                            onInput={replaceValidation}
                        />
                    </div>
                    <div className="textarea">
                      <div className="th">お問い合せ内容</div>
                      <div className="td">
                        <textarea name="text" required
                            placeholder="ご相談・お問い合せの内容をこちらにご記入ください" 
                            onBlur={Validation}
                            onInput={Validation}
                        />
                      </div>
                    </div>
                </div>
                <div data-netlify-recaptcha="true"></div>
                <div className="submit_wrap">
                    <button type="submit" id="submit">送　信</button>
                    <div className="small">このサイトはreCAPTCHAによって保護されており、<br />Googleのプライバシーポリシーと利用規約が適用されます。</div>
                </div>
			  </form>
            </div>{/*.contact-wrap*/}
          </div>{/*.contents-box*/}
        </div>{/*.row*/}
      </div>{/*.container-fluid*/}

	
	 </Layout>

)

export default contactHtml